import React from 'react'
import SbEditable from 'storyblok-react'
import { BackgroundImage } from '@system'
import { makeStyles } from '@material-ui/styles'
import { Box } from '@material-ui/core'
import classNames from 'classnames'

const useStyles = makeStyles(() => ({
  backgroundImage: {
    width: '100%',
    minHeight: 400,
    height: '30vh',
    maxHeight: 500,
    backgroundPosition: ({ backgroundPosition }) =>
      backgroundPosition || `75% top`,
  },
  backgroundOverlay: {
    zIndex: '3',
    opacity: '1',
    backgroundColor: 'rgb(3 3 3 / 60%)',
    width: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  },
  outerContainer: {
    position: 'absolute',
    width: '100%',
    left: '0px',
    top: '0px',
  },
}))

const HeaderModule = (props) => {
  const { image, hasBackgroundOverlay } = props.blok
  const classes = useStyles(props.blok)
  return (
    <SbEditable content={props.blok}>
      <Box
        className={classNames({
          [classes.outerContainer]: !!props.includesInPageOffset,
        })}
      >
        {image && (
          <BackgroundImage className={classes.backgroundImage} image={image}>
            {hasBackgroundOverlay && (
              <Box className={classes.backgroundOverlay}></Box>
            )}
          </BackgroundImage>
        )}
      </Box>
    </SbEditable>
  )
}

export default HeaderModule
